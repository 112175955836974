<template>
  <div class="blueReport-container">
    <main class="content">
      <div class="part left animate__animated animate__fadeInLeft">
        <div class="img-container">
          <img :src=imgSrc />
        </div>
      </div>
      <div class="part right animate__animated animate__fadeInRight" style="width: 550px">
        <div class="top">
          <p class="product-name">小蓝书</p>
          <ul class="product-desc">
            <li>“小蓝书”是中知数通旗下的研究报告产品。</li>
            <li>“小蓝书”目前推出两大产品系列：《小蓝书·中国企业专利价值评估报告》以及《小蓝书·中国企业科技尽调报告》</li>
          </ul>
        </div>
        <div class="bottom">
          <el-tabs
            @tab-click="handleTabClick"
            v-model="activeName"
            style="margin-top: 8px"
            class="blue-report-exchange"
          >
          <el-tab-pane label="专利价值评估报告" name="report1">
            <p class="title">专利价值评估报告</p>
            <p class="desc">
              权威专利数据库动态更新，一键生成数字化评估报告
            </p>
            <div class="button">
              <p  class="view-samples" @click="handleDownloadReport1">报告预览</p>
              <p class="log-on" @click="handleClick">{{ isLogin ? '立即使用' : '报告下载'}}</p>
            </div>
          </el-tab-pane>
          <el-tab-pane label="科技尽调报告" name="report2">
            <p class="title">科技尽调报告</p>
            <p class="desc" style="margin-bottom: 44px;">
              全面透视企业科技实力与科技潜力，让科技尽调AI起来
            </p>
            <div class="button">
              <p class="view-samples" @click="handleDownloadReport2">报告预览</p>
              <p class="log-on" @click="handleClick">{{ isLogin ? '立即使用' : '报告下载'}}</p>
            </div>
          </el-tab-pane>
        </el-tabs>
        </div>
      </div>
    </main>
    <keep-alive>
      <trial class="trial" />
    </keep-alive>
  </div>
</template>

<script>
import trial from '@/components/trial.vue'
import { mapState } from 'vuex'

export default {
  name: 'BlueReport',
  components: { trial },
  data () {
    return {
      activeName: 'report1',
      imgSrc: 'https://images.zzt.com.cn/ks/2023/04/19/blueReport.png'
    }
  },
  computed: {
    ...mapState(['isLogin'])
  },
  methods: {
    handleClick () {
      // if (!this.isLogin) {
      //   this.$router.push({ path: '/login', query: { redirect: location.href } })
      // } else {
      //   console.log('无法直接跳转使用')
      // }
      let url = process.env.VUE_APP_MODE === 'prod' ? 'https://pate.zzt.com.cn/enterprise/search' : 'https://test-pate.zzt.com.cn/enterprise/search'
      if(this.$store.state.token){
        url = `${url}?token=${this.$store.state.token}`
      }
      window.open(url)
    },
    handleTabClick(tab) {
        if(tab.name === 'report1'){
          this.imgSrc = 'https://images.zzt.com.cn/ks/2023/04/19/blueReport.png'
        }
        if(tab.name === 'report2'){
          this.imgSrc = 'https://images.zzt.com.cn/ks/2023/05/06/report.png'
        }
      },
    handleDownloadReport1() {
      window.open('https://images.zzt.com.cn/ox/2023/05/04/%E5%8D%8E%E4%B8%BA%E6%8A%80%E6%9C%AF%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8%E4%BC%81%E4%B8%9A%E4%B8%93%E5%88%A9%E4%BB%B7%E5%80%BC%E8%AF%84%E4%BC%B0%E6%8A%A5%E5%91%8A.pdf')
    },
    handleDownloadReport2() {
      window.open('https://images.zzt.com.cn/ks/2023/05/10/深圳市大疆创新科技有限公司-中国企业科技尽调报告.pdf')
    }
  },
}
</script>

<style lang="scss" scoped>
.blueReport-container {
  width: 100%;
  background: #fff;
  .content {
    width: Translate(1000px);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-top: Translate(70px);
    padding-bottom: Translate(74px);
    .left {
      .img-container {
        width: Translate(414px);
        height: Translate(344px);
        border: Translate(1px) solid #d9d9d9;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: Translate(220px);
          height: Translate(297px);
        }
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      text-align: start;
      height: Translate(364px);
      .product-name{
        font-size: Translate(24px);
        color: #666666;
        font-weight: 500;
      }
      ul{
        padding: 0;
        li{
          list-style: none;
          font-size: Translate(14px);
          color: #666666;
          line-height: Translate(24px);
          font-weight: 400;
        }
      };
      .title {
        margin-top: Translate(6px);
        font-size: Translate(24px);
        color: #333333;
        font-weight: 500;
        text-align: start;
      }
      .desc {
        margin-top: Translate(16px);
        text-align: start;
        font-size: Translate(14px);
        color: #666666;
        line-height: Translate(24px);
        font-weight: 400;
      }
      nav {
        display: flex;
        font-size: Translate(14px);
        margin-top: Translate(42px);
        margin-bottom: Translate(36px);
        .nav-item {
          position: relative;
          &::after {
            position: absolute;
            left: 50%;
            top: 100%;
            margin-top: Translate(5px);
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-width: Translate(7px) Translate(5px);
            border-style: solid;
            border-color: transparent;
            border-top-color: #4088eb;
          }
          color: #4088eb;
          font-weight: 500;
        }
        .nav-item:nth-child(2) {
          margin-left: Translate(30px);
        }
      }
      .button {
        width: Translate(335px);
        margin-top: Translate(20px);
        display: flex;
        justify-content: space-between;
        .view-samples,
        .log-on {
          width: Translate(160px);
          height: Translate(40px);
          border: Translate(1px) solid #4088eb;
          font-size: Translate(14px);
          color: #4088eb;
          text-align: center;
          line-height: Translate(42px);
          border-radius: Translate(4px);
          background-color: #ffffff;
          cursor: pointer;
          &:hover {
            background: #4088eb;
            color: #fff;
            border: Translate(1px) solid #fff;
          }
        }
      }
    }
  }
}
</style>

<style  lang="scss">
/* 重写element-ui部分样式 */
.blue-report-exchange {
  .el-tabs__active-bar {
    position: absolute;
    left: 26%;
    top: 32px;
    width: 0 !important;
    height: 0;
    border-width: 7px 5px;
    border-style: solid;
    border-color: transparent;
    border-top-color: #4088eb;
    background-color: transparent;
  }
  .el-tabs__item {
    padding: 0;
  }
  .el-tabs__nav-wrap::after {
    display: none;
  }
  .el-tabs {
    display: flex;
    flex-direction: column;
  }
  #tab-report2 {
    margin-left: 50px;
  }
}
</style>
